/// <reference path="../app/shared_controllers/common_controllers.ts"/>
///<reference path="../app/components/assets/assetsCtrl.ts"/>
///<reference path="../app/components/iroboflows/iroboflowsCtrl.ts"/>
///<reference path="../app/components/locales/localesCtrls.ts"/>
///<reference path="../app/components/login/loginCtrls.ts"/>
///<reference path="./components/projects/projectsCtrl.ts"/>
///<reference path="../app/components/roles/rolesCtrls.ts"/>
///<reference path="../app/components/schedules/scheduleCtrls.ts"/>
///<reference path="../app/components/systems/systemsCtrls.ts"/>
///<reference path="../app/components/users/usersCtrls.ts"/>
///<reference path="../app/components/queues/queuesCtrl.ts"/>
///<reference path="../app/components/dashboard/dashboard.ts"/>
///<reference path="./components/adminMain/adminMainCtrl.ts"/>

/// <reference path="../app/shared_controllers/workflowforms.ts"/>
/// <reference path="../app/components/logsReports/logsReports.ts"/> 
/// <reference path="../app/components/versioning/projects_groups.ts"/>
/// <reference path="../app/components/versioning/backups.ts"/>

var adminsRoleId = "5e7ba9cf7b16eda8438ba957";
var robotsRoleId = "5e7baa457b16eda8438ba960";
var superAdminId = "5e7baa457b16eda8438bb950";
var assetsRoleId = "5e7baa847b16eda8438ba968";
var formsUserRoleId = "5e7baa847b16eda8438ba969";
var rpaDesignerRoleId = "5e7baa567b16eda8438ba962";
var workflowUserRoleId = "5e7baa017b16eda8438ba95a";
var noderedUserRoleId = "5e7baa157b16eda8438ba95c";
var rpaSchedulerRoleId = "5e7baa847b16eda8438ba967";
var attendedrobotRoleId = "6231f7a43e82ff427c8fdaa5";
var unattendedrobotRoleId = "6231f7a43e82ff427c8fdaa6";
var queuingUsersRoleId = "623c34a0be16506bf419205d";
var flowUsersRoleId = "5e7baa847b16eda8438ba543";
var flowAdminsRoleId = "5e7baa847b16eda8438ba541";
var globalPageSize : number = 10;
var globalLoadData : number = 600;


app.config([
    <any>'$routeProvider' ,
    ($routeProvider ) => { // : angular.route.IRouteProvider
        $routeProvider

            // dashboard
            .when('/dashboard', { templateUrl: 'components/dashboard/dashboard.html', controller: dashboardCtrl , controllerAs: 'ctrl' })

            // irobo flows
            .when('/flow_tasks', { templateUrl: 'components/adminMain/main.html', controller: adminMainCtrl, controllerAs: 'ctrl' })
            .when('/flows', { templateUrl: 'components/iroboflows/workflows.html', controller: workflowsCtrl, controllerAs: 'ctrl' })
            .when('/forms', { templateUrl: 'components/iroboflows/forms.html', controller: formsCtrl, controllerAs: 'ctrl' })
            .when('/form', { templateUrl: 'components/iroboflows/form.html', controller: formCtrl, controllerAs: 'ctrl' })
            .when('/form/:id', { templateUrl: 'components/iroboflows/form.html', controller: formCtrl, controllerAs: 'ctrl' })

            // admin main pages
            .when('/files', { templateUrl: 'files.html', controller: adminFilesCtrl, controllerAs: 'ctrl' })
            .when('/history/:collection/:id', { templateUrl: 'components/adminMain/history.html', controller: historyCtrl, controllerAs: 'ctrl' })

            //assets pages
            .when('/assets', { templateUrl: 'components/assets/assets.html', controller: entitiesCtrl, controllerAs: 'ctrl' })
            .when('/addAsset', { templateUrl: 'components/assets/addAsset.html', controller: addAssetCtrl, controllerAs: 'ctrl' })
            .when('/editAsset/:id', { templateUrl: 'components/assets/editAsset.html', controller: editAssetCtrl, controllerAs: 'ctrl' })
            
            // workflows pages
            .when('/workflowinvoker/:id/:token', { templateUrl: 'components/iroboflows/workflowinvoker.html', controller: workflowinvokerCtrl, controllerAs: 'ctrl' })
            .when('/workflowinvoker/:id', { templateUrl: 'components/iroboflows/workflowinvoker.html', controller: workflowinvokerCtrl, controllerAs: 'ctrl' })

            // locales pages
            .when('/locales', { templateUrl: 'components/locales/locales.html', controller: localesCtrl, controllerAs: 'ctrl' })
            .when('/locale', { templateUrl: 'components/locales/locale.html', controller: localeCtrl, controllerAs: 'ctrl' })
            .when('/locale/:id', { templateUrl: 'components/locales/locale.html', controller: localeCtrl, controllerAs: 'ctrl' })

            // login pages
            .when('/signin', { templateUrl: 'components/login/signin.html', controller: signinCtrl, controllerAs: 'ctrl' })
            .when('/signout', { templateUrl: 'components/login/signout.html', controller: signoutCtrl, controllerAs: 'ctrl' })
            .when('/recoverpassword', { templateUrl: 'components/login/recoverpassword.html', controller: recoverPasswordCtrl, controllerAs: 'ctrl' })
            .when('/recoverpassword/:step/:param', { templateUrl: 'components/login/recoverpassword.html', controller: recoverPasswordCtrl, controllerAs: 'ctrl' })

            // logs reports pages
            .when('/logsDetailsReport', { templateUrl: 'components/logsReports/logsDetailsReport.html', controller: loggingCtrl , controllerAs: 'ctrl' })
            .when('/errorsPerComponentReport', { templateUrl: 'components/logsReports/errorsPerComponentReport.html', controller: loggingCtrl , controllerAs: 'ctrl' })
            .when('/workflowsPerUserReport', { templateUrl: 'components/logsReports/workflowsPerUserReport.html', controller: loggingCtrl , controllerAs: 'ctrl' })
            .when('/runsPerWorkflowReport', { templateUrl: 'components/logsReports/runsPerWorkflowReport.html', controller: loggingCtrl , controllerAs: 'ctrl' })
            .when('/completedFailedRobotsReports', { templateUrl: 'components/logsReports/completedFailedRobotsReports.html', controller: loggingCtrl , controllerAs: 'ctrl' })
            .when('/CamoundaInstancesStatusReport', { templateUrl: 'components/logsReports/CamoundaInstancesStatusReport.html', controller: camoundaCtrl , controllerAs: 'ctrl' })

            .when('/reports', { templateUrl: 'components/logsReports/reports.html', controller: reportsCtrl, controllerAs: 'ctrl' })

            // queues pages
            .when('/queues', { templateUrl: 'components/queues/queues.html', controller: queuesCtrl, controllerAs: 'ctrl' })
            .when('/queueItems/:id/:name', { templateUrl: 'components/queues/queueItems.html', controller: queueItemsCtrl, controllerAs: 'ctrl' })
            .when('/itemtransactions/:id', { templateUrl: 'components/queues/itemTransactions.html', controller: itemTransactionsCtrl, controllerAs: 'ctrl' })
            .when('/addQueue', { templateUrl: 'components/queues/addQueue.html', controller: addQueueCtrl, controllerAs: 'ctrl' })
            .when('/editQueue/:id', { templateUrl: 'components/queues/editQueue.html', controller: editQueueCtrl, controllerAs: 'ctrl' })

            //robots pages
            .when('/',  { templateUrl: 'components/projects/projects.html', controller: projectsCtrl, controllerAs: 'ctrl' })
            .when('/projects_list', { templateUrl: 'components/projects/projects.html', controller: projectsCtrl, controllerAs: 'ctrl' })
            .when('/robotdetectors', { templateUrl: 'components/projects/robotdetectors.html', controller: robotdetectorsCtrl, controllerAs: 'ctrl' })
           
            //roles pages
            .when('/roles', { templateUrl: 'components/roles/roles.html', controller: rolesCtrl, controllerAs: 'ctrl' })
            .when('/role', { templateUrl: 'components/roles/role.html', controller: roleCtrl, controllerAs: 'ctrl' })
            .when('/role/:id', { templateUrl: 'components/roles/role.html', controller: roleCtrl, controllerAs: 'ctrl' })

            // schedules pages
            .when('/jobschedule', { templateUrl: 'components/schedules/jobschedule.html', controller: actionscheduleCtrl , controllerAs: 'ctrl' })
            .when('/addschedule', { templateUrl: 'components/schedules/addschedule.html', controller: cronCtrl, controllerAs: 'ctrl' })
            .when('/editschedule/:id', { templateUrl: 'components/schedules/editschedule.html', controller: cronCtrl, controllerAs: 'ctrl' })
            .when('/scheduledjobs', { templateUrl: 'components/schedules/scheduledjobs.html', controller: actionscheduleCtrl , controllerAs: 'ctrl' })
            
            // systems pages
            .when('/systems', { templateUrl: 'components/systems/systems.html', controller: systemsCtrl, controllerAs: 'ctrl' })
            .when('/system', { templateUrl: 'components/systems/system.html', controller: systemCtrl, controllerAs: 'ctrl' })
            .when('/system/:id', { templateUrl: 'components/systems/system.html', controller: systemCtrl, controllerAs: 'ctrl' })

            // user pages
            .when('/users', { templateUrl: 'components/users/users.html', controller: usersCtrl, controllerAs: 'ctrl' })
            .when('/addUser', { templateUrl: 'components/users/addUser.html', controller: addUserCtrl, controllerAs: 'ctrl' })
            .when('/user/changepassword', { templateUrl: 'components/users/changepassword.html', controller: changepasswordCtrl, controllerAs: 'ctrl' })
            .when('/editUser/:id', { templateUrl: 'components/users/editUser.html', controller: editUserCtrl, controllerAs: 'ctrl' })

            // versioning pages
            .when('/backups', { templateUrl: 'components/versioning/projects_groups.html', controller: projectsVersioningCtrl, controllerAs: 'ctrl' })
            .when('/backups/:id', { templateUrl: 'components/versioning/backups.html', controller: projectsBackupsCtrl, controllerAs: 'ctrl' })


            .when('/cron', { templateUrl: 'cron.html', controller: cronCtrl, controllerAs: 'ctrl' })

            


            .otherwise({ redirectTo: '/' });

    }
])

app.config(['$compileProvider',
    function ($compileProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}]);
app.config([
    <any>'$locationProvider',
    ($locationProvider) => {
        $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix('');
       
    }
])

 








 
